/* Body */

body {
  font-family: $sans;
  color: $grey;
  
  h1,h2,h3,h4,h5,h6 {
    font-family: $serif;
    font-weight: normal;
  }
  
  img {
    max-width: 100%;
  }
  
  a {
    color: $green;
  }
  
  iframe {
    max-width: 100% !important;
    display: block;
  }
  
  svg.divider {
    position: relative;
    z-index: 2;
    width: 100%;
    display: block;
  }
}