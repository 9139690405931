section.hero {
    &.page {
        padding-top: 150px;
        background-size: cover;
        background-position: center center;
    }

    &.home {
        position: relative;
        z-index: 2;
        padding: 200px 0;
        width: 45%;
        margin: 0 auto;
        color: #fff;
        text-align: center;
        opacity: 1;
        min-width: 475px;

        &,
        * {
            transition: all 0.5s ease;
        }

        a {
            cursor: pointer;
        }

        h1 {
            margin: 0;
            font-size: 5rem;
            text-transform: uppercase;
            line-height: 1;
            font-family: $serif;
            font-weight: 400;
            display: inline-block;
            text-shadow: 0 0 20px rgba(#000, 0.6);

            em {
                font-weight: normal;
                font-family: $script;
                font-size: 0.4em;
                display: block;
                text-transform: none;
                margin: 0 0 5px;
                color: $yellow;
            }

            @include respond_to($smartphone) {
                font-size: 3.3rem;
            }
        }

        p {
            margin: 10px 0 20px;
            padding: 20px 0;
            display: inline-block;
            font-weight: 300;
            font-size: 1.45rem;
            border-bottom: 2px solid rgba(#fff, 0.25);
            border-top: 2px solid rgba(#fff, 0.25);
        }

        ul.buttons {
            li {
                display: inline-block;
                margin-top: 8px;

                + li {
                    @include respond_to($smartphone) {
                        margin-top: 8px;
                    }
                }
            }
        }

        &.hide {
            opacity: 0;
        }

        @include respond_to($smartphone) {
            padding: 100px 0;
            width: 90%;
            min-width: 0;
        }
    }
}
