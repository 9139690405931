.widget {
    h2 {
        font-weight: 300;
        margin: 0 0 10px;
        font-size: 2rem;
        opacity: 0.5;
    }

    &.upcoming-gigs {
        ul.gigs-list {
            margin: 20px 0;

            li {
                &.gig {
                    padding: 10px;
                    position: relative;
                    border-bottom: 1px dotted darken(#fff, 20);

                    h3,
                    h4 {
                        display: inline-block;
                        margin: 0;
                    }

                    hgroup {
                        padding-left: 80px;

                        p {
                            margin: 0;
                        }
                    }

                    h4 {
                        position: absolute;
                        top: 17px;
                        left: 10px;
                        color: $green;
                        font-family: $sans;
                    }

                    &:first-child {
                        border-top: 1px dotted darken(#fff, 20);
                    }
                }
            }
        }
    }

    &.news {
        ul {
            li {
                margin: 20px 0 0;

                h3 {
                    font-size: 1.2rem;
                    margin: 0 0 5px;

                    a {
                        text-decoration: none;
                    }
                }

                p {
                    font-size: 1rem;
                    margin: 0 0 5px;
                }

                .meta {
                    font-size: 0.9rem;
                    opacity: 0.6;
                    transition: all 0.4s ease;

                    &:hover {
                        opacity: 1;
                    }
                }

                + li {
                    margin-top: 30px;
                }
            }
        }
    }

    &.galleries {
        ul.galleries-list {
            li {
                &.gallery {
                    text-align: center;
                    width: 25%;
                    float: left;
                    margin: 25px 0;
                    padding: 0 15px;

                    @include respond_to($smartphone) {
                        width: 100%;
                        float: none;
                    }

                    *,
                    & {
                        transition: all 0.4s ease;
                    }

                    h3 {
                        font-size: 1rem;
                        margin: 13px 0 0;
                    }

                    a.img {
                        display: block;
                        position: relative;

                        &:after,
                        &:before {
                            content: "";
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            transition: all 0.4s ease;
                        }

                        &:after {
                            background-color: darken(#fff, 10);
                            transform: rotate(-2deg);
                        }

                        &:before {
                            background-color: darken(#fff, 15);
                            transform: rotate(3deg);
                        }

                        &:hover {
                            transform: scale(1.01);

                            &:after {
                                transform: rotate(-3deg);
                            }

                            &:before {
                                transform: rotate(4deg);
                            }
                        }
                    }

                    figure {
                        position: relative;
                        z-index: 10000000;
                        padding: 50% 0;
                        margin: 0;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;

                        img {
                            position: relative;
                            z-index: 3;
                            display: block;
                            transform: rotate(1deg);
                        }
                    }

                    &:nth-child(2n + 1) {
                        transform: rotate(1deg);
                    }

                    &:nth-child(4n + 1) {
                        margin-left: 0;
                    }

                    &:nth-child(5n + 1) {
                        transform: rotate(-2deg);
                    }

                    &:nth-child(7n + 1) {
                        transform: rotate(3deg);
                    }
                }
            }
        }
    }

    &.photo-album {
        ul.photo-album-list {
            column-count: 3;
            column-width: 25%;
            column-gap: 15px;

            li.photo {
                margin: 0 0 15px;

                img {
                    width: 100%;
                }
            }
        }
    }

    &.photos {
        .photo-gallery {
            position: relative;

            figure.photo {
                position: relative;
                margin: 0;

                img {
                    width: 100%;
                }

                figcaption {
                    //position: absolute;
                    background-color: rgba(#000, 0.8);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 20px;

                    p {
                        margin: 0;
                        font-size: 0.9rem;

                        a {
                            color: $yellow;
                        }
                    }

                    h3 {
                        font-family: $sans;
                        line-height: 1.5em;
                        margin: 10px 0 0;
                    }
                }
            }

            a.arrow {
                $icon-size: 24px;
                $padding: 40px;
                position: absolute;
                top: 50%;
                line-height: 0;
                font-size: $icon-size;
                padding: $padding 0;
                margin-top: -(($icon-size + $padding * 2) / 2);
                z-index: 10;
                cursor: pointer;

                &#next {
                    right: -($icon-size + 5);

                    @include respond_to($smartphone) {
                        right: $icon-size / 2;
                    }
                }

                &#prev {
                    left: -($icon-size + 5);

                    @include respond_to($smartphone) {
                        left: $icon-size / 2;
                    }
                }
            }
        }
    }
}
