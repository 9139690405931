@import url('https://fonts.googleapis.com/css?family=BioRhyme:300,400,700|Oswald:300,400,700|Pacifico');

/* Responsive Breakpoints */
$screen: 1100px;
$tablet: 960px;
$smartphone: 720px;
$phone: 580px;

/* Variables */
$grid_padding: 6%;
$logo_size: 125px;
$header_height: $logo_size / 1.5;

/* Fonts */
$serif: 'BioRhyme',serif;
$sans: 'Oswald',sans-serif;
$script: 'Pacifico',cursive;

/* Colors */
$grey: #323232;
$green: #005847;
$yellow: #FFCE4A;