body.single-gallery {
  main.workbench {
    text-align: center;
    
    aside.photo-gallery {
      width: 75%;
      margin: 0 auto;
      
      img {
        display: block;
        width: 100%;
      }
      
      @include respond_to($smartphone) {
        width: 100%;
      }
    }
  }
}