/* Content */

main.workbench {
    position: relative;
    z-index: 2;
    background-color: #fff;

    p,
    ul,
    ol,
    blockquote {
        font-size: 1.2rem;
        line-height: 1.7em;
        font-weight: 300;
    }

    h1 {
        font-size: 2.5rem;
        color: $green;
        margin: 0 0 20px;

        small {
            display: block;
            font-size: 1rem;
            font-family: $sans;
            margin: 0 0 10px;
            font-weight: normal;

            a {
                + a {
                    &:before {
                        content: "•";
                        padding: 0 10px;
                    }
                }

                &:hover {
                    text-decoration: none;
                    color: darken($yellow, 20);

                    &:before {
                        color: $green;
                    }
                }
            }
        }
    }

    section.row {
        $section_padding: 35px;
        padding: $section_padding 0;

        .divider {
            &.top {
                margin-top: -($section_padding);
                margin-bottom: $section_padding;
            }

            &.bottom {
                margin-top: $section_padding;
                margin-bottom: -($section_padding);
            }
        }

        &.gradient {
            color: #fff;
            @include linear-gradient(45deg, $green, #85d20b);

            h2 {
                opacity: 1;
            }

            &.centered {
                text-align: center;
            }
        }
    }
}
