.button,
input[type="button"],
input[type="submit"] {
    line-height: 1;
    padding: 15px 65px;
    background-color: $green;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    font-family: $sans;
    text-transform: uppercase;
    font-size: 1.1rem;
    position: relative;
    text-align: center;
    outline: none;
    font-size: 18px;
    border: none;
    transform: translateZ(0);
    border-radius: 5px;

    i {
        margin-right: 5px;
    }

    &.transparent {
        border-width: 2px;
        border-style: solid;
        background-color: transparent;

        &:before {
            border-radius: 3px;
        }
    }

    &.white {
        &.transparent {
            border-color: #fff;
        }

        &:before {
            background-color: #fff;
        }

        &:hover {
            color: $green;
        }
    }

    &.medium {
        padding: 12px 40px;
        font-size: 0.95em;
    }

    &.small {
        padding: 8px 20px;
        font-size: 0.75em;
    }

    &.same-width {
        padding-left: 20px;
        padding-right: 20px;
        width: 250px;
    }

    &.full-width {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        background-color: darken($green, 5);
        z-index: -1;
        transition: width 0.3s ease;
        border-radius: 5px;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}
