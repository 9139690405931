$no_of_columns: 12;
$single_col: (100 - ($grid_padding * ($no_of_columns - 1))) / $no_of_columns;

@mixin get_column_width($n: 1) {
	width: ($single_col * $n) + ($grid_padding * ($n - 1));
}

@mixin set_repeating($n: 1) {
  	&.col-repeat {
    	&:nth-child(#{$n}n+1) {
      	margin-left: 0;
    	}
    	
    	&:nth-child(n+#{$n+1}) {
      	margin-top: $grid_padding;
    	}
  	}
}

[class*='col-'] {
	float: left;
	
	& + [class*='col-'] {
		margin-left: $grid_padding;
		
		@include respond_to($phone) {
  		  margin: 25px 0 0;
      padding-top: 25px;
      border-top: 1px solid rgba(#000,0.2);
  		}
	}
	
	&.col-12 {
    	@include get_column_width(12);
  }
	
	&.col-11 {
    	@include get_column_width(11);
  }
	
	&.col-10 {
    	@include get_column_width(10);
  }
	
	&.col-9, &.col-3-4 {
  	  @include get_column_width(9);
  }
  
  &.col-8, &.col-2-3 {
    	@include get_column_width(8);
  }
  
  &.col-7 {
    	@include get_column_width(7);
  }
  
  &.col-6, &.col-1-2 {
    	@include get_column_width(6);
    	@include set_repeating(2);
  }
  
  &.col-5 {
    	@include get_column_width(5);
  }
  
  &.col-4, &.col-1-3 {
    	@include get_column_width(4);
    @include set_repeating(3);
  }
  
  &.col-3, &.col-1-4 {
    	@include get_column_width(3);
  }
  
  &.col-2 {
    	@include get_column_width(2);
  }
  
  &.col-1 {
    	@include get_column_width(1);
  }
	
	@include respond_to($smartphone) {
		float: none;
		width: 100% !important;
	}
}