body.blog {
    ul.posts-grid {
        column-count: 3;
        column-gap: 20px;
        column-fill: auto;

        li {
            display: inline-block;
            padding: 20px;
            background-color: darken(#fff, 5);
            margin: 0 0 20px;

            h2 {
                margin: 0 0;
                font-size: 1.4rem;

                a {
                    text-decoration: none;
                }
            }

            p {
                font-size: 1.1rem;
                margin: 0 0 10px;
            }

            .meta {
                font-style: italic;
                font-size: 0.9rem;
                margin: 0 0 10px;
            }
        }

        @include respond_to($smartphone) {
            column-count: 1;
            column-gap: 0;
        }
    }
}
