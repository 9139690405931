figure.background-video {
    position: fixed;
    top: -50%;
    left: -50%;
    z-index: 0;
    width: 200%;
    height: 200%;

    video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        margin: auto;
        min-width: 50%;
        min-height: 50%;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#000, 0.2);
        z-index: 2;
        opacity: 1;
        transition: all 0.5s ease;
    }

    &.play {
        &:after {
            opacity: 0;
        }
    }
}
