/* Base Styles */

html,
body {
    height: 100%;

    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        line-height: 1em;
    }

    ul {
        &.plain {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    img {
        &.alignleft {
            float: left;
            margin-right: 20px;
        }

        &.alignright {
            float: right;
            margin-left: 20px;
        }

        &.alignleft,
        &.alignright {
            margin-bottom: 20px;
        }
    }

    .wrap {
        max-width: $screen;
        margin: 0 auto;
        position: relative;
        width: 90%;

        &.nopadding {
            padding: 0;
        }

        @include respond_to($phone) {
            margin: 0;
            padding: 0 6%;
            width: 100%;
        }
    }

    .full {
        width: 100%;
    }

    .group,
    .wrap {
        zoom: 1;

        &:before,
        &:after {
            display: table;
            content: "";
        }

        &:after {
            clear: both;
        }
    }
}
