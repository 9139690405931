/*!
Theme Name: St. Edward Trash Talkers
Theme URI:
Description:
Author: Nick Goers
Author URI:
Version: 1.0
Template: nickysix-boilerplate
*/

@import "assets/src/sass/vendor/bootstrap-reboot.min.css";

@import "init/variables";
@import "init/mixins";
@import "init/base";
@import "init/grid";

@import "global";
@import "header";
@import "content";
@import "footer";

@import "pages/blog";
@import "pages/gallery";

@import "components/components";
