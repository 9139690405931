/* Footer */

footer.main {
  text-align: center;
  position: relative;
  z-index: 2;
  background-color: #FFF;
  font-size: 0.9rem;
  padding: 30px;
  
  nav {
    ul {
      li {
        display: inline-block;
        font-size: 1.2rem;
        padding: 0 20px;
        
        a {
          text-transform: uppercase;
          font-weight: 300;
          color: darken(#FFF,45);
          text-decoration: none;
          
          &:hover {
            color: $green;
          }
        }
      }
    }
    
    @include respond_to($smartphone) {
      display: none;
    }
  }
  
  .copyright {
    p {
        font-weight: 300;
        margin: 10px 0 0;
    }
  }
}