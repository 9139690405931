/* Header */

$menu_mobile: $smartphone;

header.main {
    position: relative;
    z-index: 10;
    background-color: #fff;
    height: $header_height;

    a {
        &.logo {
            display: block;
            margin: 0 auto;
            margin-left: -($logo_size / 2);
            top: 0;
            width: $logo_size;

            img {
                width: 100%;
                display: block;
            }

            @include respond_to(1060px) {
                margin-left: -(100px / 2);
                width: 100px;
            }

            @include respond_to($menu_mobile) {
                margin-left: -(85px / 2);
                width: 85px;
            }
        }

        &.sponsor {
            top: 100%;
            margin-left: 60px;
            width: $logo_size + 50;

            @include respond_to(1060px) {
                margin-left: 40px;
            }

            @include respond_to(1060px) {
                width: $logo_size + 75;
                margin-left: -(($logo_size + 75) / 2);
                margin-top: 30px;
            }
        }

        &.logo,
        &.sponsor {
            position: absolute;
            left: 50%;
            z-index: 4;
        }
    }

    nav.menu {
        $menu_item_spacer: 30px;
        $menu_logo_spacer: ($logo_size / 2) + $menu_item_spacer;
        position: absolute;
        width: 50%;
        z-index: 3;
        font-weight: 300;

        * {
            transition: all 0.3s ease;
        }

        ul {
            li {
                line-height: $header_height;
                display: inline-block;
                text-transform: uppercase;
                font-size: 1.2rem;

                a {
                    color: darken(#fff, 45);
                    text-decoration: none;
                    padding: 0 10px;
                    display: block;

                    &:hover {
                        color: $green;
                    }
                }

                @include respond_to(1060px) {
                    font-size: 1rem;
                }
            }
        }

        &.left,
        &.right {
            @include respond_to($menu_mobile) {
                display: none;
            }
        }

        &.left {
            left: 0;
            right: 50%;
            text-align: right;
            padding-right: $menu_logo_spacer;

            ul {
                li {
                    margin-left: $menu_item_spacer;

                    @include respond_to(1060px) {
                        margin-left: $menu_item_spacer - 25px;
                    }
                }
            }

            @include respond_to(1060px) {
                padding-right: $menu_logo_spacer - 35;
            }
        }

        &.right {
            right: 0;
            left: 50%;
            text-align: left;
            padding-left: $menu_logo_spacer;

            ul {
                li {
                    margin-right: $menu_item_spacer;

                    @include respond_to(1060px) {
                        margin-right: $menu_item_spacer - 25px;
                    }
                }
            }

            @include respond_to(1060px) {
                padding-left: $menu_logo_spacer - 35;
            }
        }

        &.mobile {
            display: none;
            float: right;
            position: static;
            width: auto;

            ul {
                display: none;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 9999;
                text-align: center;
                padding: 50px 0;
                @include linear-gradient(45deg, $green, #85d20b);

                li {
                    display: block;
                    line-height: normal;

                    a {
                        color: #fff;
                        padding: 15px 0;
                        font-size: 1.5rem;
                    }
                }
            }

            a.mobile-toggle {
                color: darken(#fff, 30);
                font-size: 1.3rem;
                line-height: 60px;
                padding: 0 20px;
                position: relative;
                z-index: 10000;

                &.on {
                    color: #fff;
                }
            }

            @include respond_to($menu_mobile) {
                display: block;
            }
        }
    }

    @include respond_to($menu_mobile) {
        height: 60px;
    }
}
